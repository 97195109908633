import { initFadeIn } from './vendor/fade-in.js';
import { breakpoints } from '../variables.yml';
import 'focus-visible';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { buildVimeoPlayers, buildYoutubePlayers, getQueryObject, getQueryString } from './helpers.js';
import 'swiper/css';
import EmblaCarousel from 'embla-carousel';
import EmblaCarouselAutoplay from 'embla-carousel-autoplay';
import Fade from 'embla-carousel-fade';

export * from './CookieConsentManager.js';

( () => { // Fade In
	initFadeIn();
} )();

( () => { // Nav open/close
	const nav_open = document.querySelector( '.header__nav-open' );
	const nav_close = document.querySelector( '.nav__close' );
	const nav = document.querySelector( '.nav' );
	if ( !nav_open || !nav || !nav_close ) {
		return;
	}

	nav_open.onclick = () => {
		document.body.classList.add( 'nav-visible' );
		nav.focus();
	};

	nav_close.onclick = () => {
		document.body.classList.remove( 'nav-visible' );
	};
} )();

( () => { // Expandable nav items
	function setNavItemHeights() {
		const nav_items = document.querySelectorAll( '.nav__expandable-nav-item' );

		function closeOtherNavItems( index ) {
			nav_items.forEach( ( nav_item, i ) => {
				if ( i === index ) {
					return;
				}
				nav_item.classList.remove( 'nav__expandable-nav-item--expanded' );
				nav_item.style.maxHeight = null;
			} );
		}

		nav_items.forEach( ( nav_item, index ) => {
			const toggle = nav_item.querySelector( '.nav__sub-nav-toggle' );
			const sub_nav_items = nav_item.querySelectorAll( '.nav__sub-nav-item a' );
			if ( !toggle ) {
				return;
			}

			const max_nav_item_height = nav_item.scrollHeight + 4;
			nav_item.style.height = `${max_nav_item_height}px`;

			toggle.onclick = () => {
				if ( nav_item.classList.contains( 'nav__expandable-nav-item--expanded' ) ) {
					nav_item.classList.remove( 'nav__expandable-nav-item--expanded' );
					nav_item.style.maxHeight = null;
					return;
				}
				nav_item.classList.add( 'nav__expandable-nav-item--expanded' );
				nav_item.style.maxHeight = `${max_nav_item_height}px`;
				closeOtherNavItems( index );
			};

			sub_nav_items.forEach( sub_nav_item => {
				sub_nav_item.onfocus = () => {
					nav_item.classList.add( 'nav__expandable-nav-item--expanded' );
					nav_item.style.maxHeight = `${max_nav_item_height}px`;
					closeOtherNavItems( index );
				};
			} );
		} );
	}

	window.addEventListener( 'resize', () => {
		setNavItemHeights();
	} );

	setNavItemHeights();
} )();

( () => { // Energy Mode
	const energy_mode_on = +localStorage.getItem( 'energy_mode_on' );
	if ( energy_mode_on ) {
		document.body.classList.add( 'energy-mode' );
	}

	const energy_mode_toggler = document.querySelector( '.header__energy-toggle' );

	energy_mode_toggler.onclick = () => {
		if ( !document.body.classList.contains( 'bg-transition-smooth' ) ) {
			document.body.classList.add( 'bg-transition-smooth' );
		}
		document.body.classList.toggle( 'energy-mode' );
		const is_toggled = document.body.classList.contains( 'energy-mode' );
		localStorage.setItem( 'energy_mode_on', is_toggled ? 1 : 0 );
		energy_mode_toggler.setAttribute( 'aria-label', `Toggle energy-saving mode ${ is_toggled ? 'off' : 'on' }` );
	};

	// Remove transition speed on page load to remove white flicker
	window.addEventListener( 'load', () => {
		document.body.classList.remove( 'bg-transition-smooth' );
	} );
} )();

( () => { // Read More
	function setReadMoreHeightStyles() {
		const read_mores = document.querySelectorAll( '.read-more' );

		read_mores.forEach( read_more => {
			const content = read_more.querySelector( '.read-more__content' );
			if ( !content ) {
				return;
			}
			const all_text_height = content.scrollHeight;

			const button = read_more.querySelector( '.read-more__button' );
			if ( all_text_height < 156 ) {
				button.classList.add( 'read-more__button--hide' );
				return;
			}

			button.classList.remove( 'read-more__button--hide' );
			const open_height_style = `${all_text_height}px`;
			const closed_height_style = '118px';

			content.style.height = open_height_style;
			content.style.maxHeight = closed_height_style;

			button.onclick = () => {
				button.classList.toggle( 'read-more__button--open' );

				if ( button.classList.contains( 'read-more__button--open' ) ) {
					button.innerHTML = 'Read Less';
					content.style.maxHeight = open_height_style;
				} else {
					button.innerHTML = 'Read More';
					content.style.maxHeight = closed_height_style;
				}
			};
		} );
	}

	let previous_window_width = window.innerWidth;

	window.addEventListener( 'resize', ( e ) => {
		const current_window_width = e.target.innerWidth;

		// Scrolling on mobile triggers a resize as toolbars slide in and out, so ignore these
		if ( current_window_width === previous_window_width ) {
			return;
		}

		previous_window_width = current_window_width;
		setReadMoreHeightStyles();
	} );

	setReadMoreHeightStyles();
} )();

( () => { // Vimeo Players
	const videos = document.querySelectorAll( '.video--vimeo' );
	if ( !videos.length ) {
		return;
	}
	window.addEventListener( 'load', ()=> {
		buildVimeoPlayers( videos );
	} );
} )();

( () => { // Full Vimeo URL fallback image. This Vimeo autoplays and will show an image if it fails to load
	const vimeos = document.querySelectorAll( '.vimeo-autoplay__container' );
	if ( !vimeos.length ) {
		return;
	}
	const vimeo_containers = document.querySelectorAll( '.vimeo-autoplay__container' );
	for ( let i = 0; i < vimeo_containers.length; i++ ) {
		const vimeo_player = vimeo_containers[i].querySelector( '.vimeo-autoplay' );
		const fallback_image = vimeo_containers[i].querySelector( '.vimeo-autoplay__fallback-img' );
		if ( !fallback_image ) {
			return;
		}
		setTimeout( () => { //Wait for the vimeo to load on page to get its height
			if ( vimeo_player && vimeo_player.getBoundingClientRect().height < 200 ) {
				vimeo_player.style.display = 'none';
				fallback_image.style.display = 'block';
			}
		}, 1000 );
	}
} )();

( () => { // Fallback images same height as vimeos.
	const vimeo = document.querySelector( '.vimeo-autoplay' );
	const fallback_images = document.querySelectorAll( '.vimeo-autoplay__fallback-img' );
	if ( !vimeo || !fallback_images.length ) {
		return;
	}
	setTimeout( () => { //Wait for the vimeo to load on page to get its height
		for ( let i = 0; i < fallback_images.length; i++ ) {
			fallback_images[i].style.height = vimeo.offsetHeight + 'px';
			fallback_images[i].style.objectFit = 'cover';
		}
	}, 1000 );
} )();

( () => { // Youtube Players
	const videos = document.querySelectorAll( '.video--youtube' );
	if ( !videos.length ) {
		return;
	}

	buildYoutubePlayers( videos );
} )();

( () => { // Three Column Carousels
	const three_columns_carousels = document.querySelectorAll( '.three-column-carousel' );
	if ( !three_columns_carousels.length ) {
		return;
	}

	const carousel_breakpoints = {};
	const d_breakpoint = breakpoints.d.width;
	const f_breakpoint = breakpoints.f.width;

	carousel_breakpoints[f_breakpoint] = {
		slidesPerView: 2,
		spaceBetweenSlides: 24
	};
	carousel_breakpoints[d_breakpoint] = {
		slidesPerView: 3,
		spaceBetweenSlides: 24
	};

	three_columns_carousels.forEach( carousel => {
		new Swiper( carousel, {
			modules: [Navigation],
			slidesPerView: 1,
			spaceBetween: 16,
			navigation: {
				nextEl: '.three-column-carousel__next',
				prevEl: '.three-column-carousel__prev',
			},
			breakpoints: carousel_breakpoints
		} );
	} );
} )();
function initialiseImageCarousels() {
	const image_carousels = document.querySelectorAll( '.embla' );
	if ( !image_carousels.length ) {
		return;
	}

	image_carousels.forEach( carousel_element => {

		let carousel_api = null;
		const options = { loop: true };
		const autoplay_delay = parseInt( carousel_element.dataset.autoplayDelay, 10 );
		if ( autoplay_delay ) {
			const autoplay = EmblaCarouselAutoplay( { delay: autoplay_delay } );
			carousel_api = EmblaCarousel( carousel_element, options, [autoplay, Fade()] );
		} else {
			carousel_api = EmblaCarousel( carousel_element, options, [Fade()] );
		}

		// Set up buttons
		const next_button = carousel_element.querySelector( '.image-carousel__next' );
		const prev_button = carousel_element.querySelector( '.image-carousel__prev' );
		if ( next_button && prev_button ) {
			prev_button.addEventListener( 'click', carousel_api.scrollPrev, false );
			next_button.addEventListener( 'click', carousel_api.scrollNext, false );
		}

		// Update slide counter
		const progress_index = carousel_element.querySelector( '.image-carousel__current-index' );
		if ( progress_index ) {
			carousel_api.on( 'scroll', () => {
				progress_index.innerHTML = carousel_api.selectedScrollSnap() + 1;
			} );
		}

		// // Add slideshow navigation on image click
		const slides = carousel_element.querySelectorAll( '.image-carousel__slide' );
		slides.forEach( slide => {
			slide.addEventListener( 'click', ( event ) => {
				const slide_width = slide.offsetWidth;
				const x = event.offsetX;
				if ( x < slide_width / 2 ) {
					carousel_api.scrollPrev();
				} else {
					carousel_api.scrollNext();
				}
			} );
		} );

		// Lightbox
		const lightbox_wrapper = carousel_element.closest( '.lightbox' );
		const fullscreen_open = carousel_element.querySelector( '.image-carousel__fullscreen-open' );
		const fullscreen_close = carousel_element.querySelector( '.image-carousel__fullscreen-close' );

		if ( lightbox_wrapper && fullscreen_open && fullscreen_close ) {
			enableLightbox( lightbox_wrapper, carousel_api, fullscreen_open, fullscreen_close );
		}

	} );

	function enableLightbox( lightbox_wrapper, carousel_api, fullscreen_open, fullscreen_close ) {
		fullscreen_open.addEventListener( 'click', () => {
			document.body.classList.add( 'lightbox-active' );
			lightbox_wrapper.classList.add( 'lightbox--active' );
			carousel_api.scrollTo( carousel_api.selectedScrollSnap(), true );
		} );

		fullscreen_close.addEventListener( 'click', () => {
			document.body.classList.remove( 'lightbox-active' );
			lightbox_wrapper.classList.remove( 'lightbox--active' );
			carousel_api.scrollTo( carousel_api.selectedScrollSnap(), true );
		} );
	}
}

initialiseImageCarousels();


( () => { // Accordions
	const accordions = document.querySelectorAll( '.accordion' );

	accordions.forEach( accordion => {
		function setAccordionHeightStyles() {
			const toggle = accordion.querySelector( '.accordion__toggle' );

			const toggle_height = toggle.offsetHeight;
			const total_height = accordion.scrollHeight;

			const open_height_style = `${total_height}px`;
			const closed_height_style = `${toggle_height}px`;

			accordion.style.height = open_height_style;
			accordion.style.maxHeight = closed_height_style;

			toggle.onclick = () => {
				if ( accordion.classList.contains( 'accordion--open' ) ) {
					accordion.classList.remove( 'accordion--open' );
					accordion.style.maxHeight = closed_height_style;
				} else {
					accordion.classList.add( 'accordion--open' );
					accordion.style.maxHeight = open_height_style;
				}
			};
		}

		let previous_window_width = window.innerWidth;

		window.addEventListener( 'resize', ( e ) => {
			const current_window_width = e.target.innerWidth;

			// Scrolling on mobile triggers a resize as toolbars slide in and out, so ignore these
			if ( current_window_width === previous_window_width ) {
				return;
			}

			previous_window_width = current_window_width;
			setAccordionHeightStyles();
		} );

		setAccordionHeightStyles();
	} );
} )();

( () => { // Steps
	const steps_blocks = document.querySelectorAll( '.content-block--steps' );

	steps_blocks.forEach( block => {
		function setBlockHeight() {
			setDescriptionHeight(block);
			setImageHeight( block );
		}

		function createTransitions() {
			const step_title_buttons = block.querySelectorAll( '.steps__step-title-button' );
			const step_descriptions = block.querySelectorAll( '.steps__step-description' );
			const step_images = block.querySelectorAll( '.steps__image-wrapper' );

			step_title_buttons.forEach( button => {
				button.onclick = () => {
					const step_index = button.dataset.stepTitleIndex;

					step_title_buttons.forEach( title_button => {
						const button_index = title_button.dataset.stepTitleIndex;

						if ( button_index === step_index ) {
							title_button.classList.add( 'steps__step-title-button--active' );
							return;
						}
						title_button.classList.remove( 'steps__step-title-button--active' );
					} );

					step_descriptions.forEach( description => {
						const description_index = description.dataset.stepDescriptionIndex;

						if ( description_index === step_index ) {
							description.classList.add( 'steps__step-description--visible' );
							return;
						}
						description.classList.remove( 'steps__step-description--visible' );
					} );

					step_images.forEach( image => {
						const image_index = image.dataset.stepImageIndex;

						if ( image_index === step_index ) {
							image.classList.add( 'steps__image-wrapper--visible' );
							return;
						}
						image.classList.remove( 'steps__image-wrapper--visible' );
					} );

					setBlockHeight();
					
				};
			} );
		}

		
		function setDescriptionHeight( block ) {
			const descriptions_container = block.querySelector( '.steps__description-container' );
			const active_description = block.querySelector( '.steps__step-description--visible' );
			descriptions_container.style.height = `${active_description.clientHeight}px`;
		}

		function setImageHeight( block ) {
			const image_container = block.querySelector( '.steps__image-container' );
			const active_image = block.querySelector( '.steps__image-wrapper--visible' );
			image_container.style.height = `${active_image.clientHeight}px`;
		}

		window.addEventListener( 'resize', () => {
			setBlockHeight();
		} );

		window.addEventListener( 'load', () => {
			setBlockHeight();
		} );

		setBlockHeight();
		createTransitions();
	} );
} )();

( () => { // Project Archive Table
	const archive_table_row_triggers = document.querySelectorAll( '.project-archive-table__row--trigger' );
	archive_table_row_triggers.forEach( trigger => {

		const button = trigger.querySelector( '.project-archive__project-title' );
		const project_id = trigger.dataset.projectId;
		let expandable_row = document.querySelector( `.project-archive-table__row--expandable[data-project-id="${project_id}"]` );
		let row_contents = null ;
		let open_height_style = null;

		if ( !button || !project_id || !expandable_row ) {
			return;
		}

		trigger.addEventListener( 'click', function(){ fetchRowContent() } );

		function fetchRowContent() {
			if ( !row_contents ) {
				// // Fetch content from the server
				fetch( `api/dropdown-content?id=${project_id}` )
				.then( function( response ) {
					if (!response.ok) {
						throw new Error( 'Network response was not ok');
					}
					console.log( "response is ok" );
					return response.text();
				} )
				.then( function( data ) {
					// Insert data into HTML
					expandable_row.innerHTML = data;
					row_contents = expandable_row.querySelectorAll( '.project-archive-table__row-content' );
					calculateRowHeight();
					handleDropdown();
					initialiseImageCarousels();
				})
				.catch(function(error) {
					console.log(error);
				});
			} else {
				handleDropdown();
			}
		}

		function handleDropdown() {
			console.log(expandable_row.classList);
			if ( expandable_row.classList.contains( 'project-archive-table__row--expanded' ) ) {
				button.classList.remove( 'project-archive__project-title--active' );
				expandable_row.classList.remove( 'project-archive-table__row--expanded' );
				expandable_row = document.querySelector( `.project-archive-table__row--expandable[data-project-id="${project_id}"]` );
				row_contents.forEach( row_content => {
					row_content.style.maxHeight = null;
				} );
			} else {
				button.classList.add( 'project-archive__project-title--active' );
				expandable_row.classList.add( 'project-archive-table__row--expanded' );
				expandable_row = document.querySelector( `.project-archive-table__row--expandable[data-project-id="${project_id}"]` );
				row_contents.forEach( row_content => {
					row_content.style.maxHeight = open_height_style;
				} );
			}
		}

		function calculateRowHeight() {
			// Collapse all rows
			button.classList.remove( 'project-archive__project-title--active' );
			if ( expandable_row.classList.contains( 'project-archive-table__row--expanded' ) ) {
				expandable_row.classList.remove( 'project-archive-table__row--expanded' );
			}
			row_contents.forEach( row_content => {
				// Temporarily remove overflow hidden from lightbox to get its height
				const lightbox = row_content.querySelector( '.lightbox' );
				if ( lightbox ) {
					lightbox.style.overflow = "visible";
				}
				row_content.style.maxHeight = null;
			} );

			// Get highest row content height (text or carousel)
			const highest_row_height = [ ...row_contents ]
				.map( row_content => row_content.scrollHeight )
				.sort( ( a, b ) => a < b ? 1 : -1 )
				.at( 0 )
			;

			open_height_style = `${highest_row_height + 32}px`;

			row_contents.forEach( row_content => {
				row_content.style.height = open_height_style;
				// Add back overflow hidden after getting its height
				const lightbox = row_content.querySelector( '.lightbox' );
				if ( lightbox ) {
					lightbox.style.overflow = "hidden";
				}
			} );
		}
		
		let previous_window_width = window.innerWidth;

		window.addEventListener( 'resize', ( e ) => {
			const current_window_width = e.target.innerWidth;

			// Scrolling on mobile triggers a resize as toolbars slide in and out, so ignore these
			if ( current_window_width === previous_window_width ) {
				return;
			}

			previous_window_width = current_window_width;
			calculateRowHeight();
		} );

	} );
} )();

( () => { // Resize entry listing animations
	const animation_wrappers = document.querySelectorAll( '.entry-listing__image-wrapper--animation' );

	animation_wrappers.forEach( wrapper => {
		const animation = wrapper.querySelector( '.entry-listing__animation' );
		if ( !animation ) {
			return;
		}

		function centerAnimation() {
			const wrapper_aspect_ratio = wrapper.offsetWidth / wrapper.offsetHeight;
			const animation_aspect_ratio = animation.offsetWidth / animation.offsetHeight;

			if ( wrapper_aspect_ratio < animation_aspect_ratio ) {
				const offset = ( animation.offsetWidth - wrapper.offsetWidth ) / 2;
				animation.style.height = '100%';
				animation.style.width = 'auto';
				animation.style.transform = `translateX( -${ offset }px )`;
			} else if ( wrapper_aspect_ratio > animation_aspect_ratio ) {
				const offset = ( animation.offsetHeight - wrapper.offsetHeight ) / 2;
				animation.style.height = 'auto';
				animation.style.width = '100%';
				animation.style.transform = `translateY( -${ offset }px )`;
			} else {
				animation.style.height = '100%';
				animation.style.width = '100%';
			}
		}

		window.addEventListener( 'resize', centerAnimation );

		centerAnimation();
	} );
} )();

( () => { // Search components
	const search_components = document.querySelectorAll( '.search' );

	// Expand search on input click
	search_components.forEach( search => {
		if ( search.classList.contains( 'search--no-filters' ) ) {
			return;
		}

		function setSearchHeightStyles() {
			const query_input = search.querySelector( '.search__query' );

			const open_height_style = `${search.scrollHeight}px`;

			search.style.height = open_height_style;
			if ( search.classList.contains( 'search--expand' ) ) {
				search.style.maxHeight = open_height_style;
			}

			query_input.onclick = () => {
				search.classList.add( 'search--expand' );
				search.style.maxHeight = open_height_style;
			};
		}

		window.addEventListener( 'resize', () => {
			setSearchHeightStyles();
		} );

		setSearchHeightStyles();
	} );

	// Search on filter select and reset inputs on reset click
	search_components.forEach( search => {
		if ( search.classList.contains( 'search--no-filters' ) ) {
			return;
		}

		const form = search.querySelector( 'form' );
		const query_input = search.querySelector( '.search__query-input' );
		const search_filter_sets = search.querySelectorAll( '.search__filters' );
		const search_reset = search.querySelector( '.search__reset' );

		if ( !form || !query_input || search_filter_sets.lenght < 1 ) {
			return;
		}

		search_filter_sets.forEach( filter_set => {
			const filter_inputs = filter_set.querySelectorAll( '.search__filter-input' );

			filter_inputs.forEach( ( input, i ) => {
				input.onchange = () => {
					if ( input.classList.contains( 'search__filter-input--all' ) ) {
						uncheckAllOtherInputs( i );
					} else {
						uncheckAllFiltersInput();
					}
					submitForm( form );
				};
			} );

			function uncheckAllFiltersInput() {
				filter_inputs.forEach( ( input ) => {
					if ( input.classList.contains( 'search__filter-input--all' ) ) {
						input.checked = false;
					}
				} );
			}

			function uncheckAllOtherInputs( index ) {
				filter_inputs.forEach( ( input, i ) => {
					if ( i !== index ) {
						input.checked = false;
					}
				} );
			}
		} );

		if ( !search_reset ) {
			return;
		}

		// Reset form
		search_reset.onclick = () => {
			query_input.value = '';

			search_filter_sets.forEach( filter_set => {
				const filter_inputs = filter_set.querySelectorAll( '.search__filter-input' );
				filter_inputs.forEach( input => {
					if ( input.classList.contains( 'search__filter-input--all' ) ) {
						input.checked = true;
					} else {
						input.checked = false;
					}
				} );
			} );
			form.submit();
		};
		
		form.addEventListener( 'submit', ( e ) => {
			e.preventDefault();
			submitForm( form );
		});

	} );

	// Close search on close button click
	search_components.forEach( search => {
		if ( search.classList.contains( 'search--no-filters' ) ) {
			return;
		}

		const search_close = search.querySelector( '.search__close' );

		search_close.onclick = () => {
			search.classList.remove( 'search--expand' );
			search.style.maxHeight = null;
		};
	} );

	function submitForm( form ) {
		const query_string = `${ '?' + new URLSearchParams( new FormData( form ) ) }`;
		const query_object = getQueryObject( query_string );
		const reformatted_query_string = getQueryString( query_object );
		window.location = form.action + reformatted_query_string;
	}
} )();
